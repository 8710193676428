import Text from "../../../Misc/text/Text";
import LayoutFillImg from "../../../Misc/LayoutFillImg/LayoutFillImg";

import styles from "./about.module.scss";
const About = () => {
  return (
    <section className={styles.aboutmainContainer}>
      <div className={styles.aboutContainer}>
        <div className={styles.subContainer}>
          <h1 className={styles.aboutHeading}>About Us</h1>
          <Text className={styles.aboutDisc}>
            We are a team of leading premium Bay Area outdoor living contractors
            who design, plan, engineer and develop exemplary outdoor living
            spaces.
          </Text>
          <Text>
            We have solidified a high fidelity workflow that has delivered world
            class results to our clients.
          </Text>
          <Text>
            We want you to enjoy the same level of quality on your one in a
            lifetime project
          </Text>
        </div>
        <div className={styles.aboutRoomContainer}>
          <div className={styles.aboutRoomBox}>
            <LayoutFillImg src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1738317134/Home%20-%20Assets/347008782_607627804435950_3381949438082163034_n_buhl6o.jpg" alt="about Image 1" />
          </div>
          <div className={styles.aboutRoomBox2}>
            <div>
              <LayoutFillImg src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1738317134/Home%20-%20Assets/466503574_18150157174338231_4668162486481324465_n_ecjrhx.jpg" alt="about Image 2" />
            </div>
            <p className={styles.aboutRoomDisc}>Our work speaks for itself.</p>
            <Text className={styles.aboutRoomDisc}>
              Our tools and workflows give your project the best chance of
              success and your vision the professionalism it needs to become a reality.
            </Text>
          </div>
          <div className={styles.aboutRoomBox}>
            <LayoutFillImg src="https://res.cloudinary.com/dxqnocwzs/image/upload/v1738317135/Home%20-%20Assets/474746937_1145619290569908_3581275264273199023_n_yaqntc.jpg" alt="about Image 3" />
          </div>
        </div>
      </div>

      <div className={styles.aboutCorner}>
        <LayoutFillImg src="/assets/about-corner.svg" alt="about corner" />
      </div>
    </section>
  );
};

export default About;
